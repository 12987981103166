import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import animation1 from "../mobile-animations/nivo1_bg.json";
import counteranimation from "../mobile-animations/counter.json";
import Lottie from "lottie-web";

import {
  controlOptions,
  scoreState,
  timerState,
  positionTimerState,
} from "./atom";
import { SPEED_STEP, SPAWN_INTERVAL } from "./constants";
import Control from "./Control";
import Score from "./Score";
import Timer from "./Timer";

export default ({
  currentSession,
  updateLevel,
  isLoading,
  currentLevel,
  handleNextLevel,
  endScreen,
  tenisOpen,
  tenisTimer,
}) => {
  const animationContainer = useRef(null);
  const counterAnimationContainer = useRef(null);
  const directionIndicator = useRef(null);
  const powerIndicator = useRef(null);
  const ballRef = useRef(null);
  const racketRef = useRef(null);
  const hitGrid = useRef(null);
  const [controlState, setControlState] = useRecoilState(controlOptions);
  const [score, setScore] = useRecoilState(scoreState);
  const [timer, setTimer] = useRecoilState(timerState);
  const [positionTimer, setPositionTimer] = useRecoilState(positionTimerState);
  const requestRef = useRef();
  const timerRef = useRef();
  const positionTimerRef = useRef();
  const [rowCellArray, setRowCellArray] = useState([]);
  const [gameStateCheck, setGameStateCheck] = useState(false);
  const [itterations, setItterations] = useState(1);
  const [gameStateChecker, setGameStateChecker] = useState(false);
  const [slotIndex, setSlotIndex] = useState(5);
  const [direction, setDirection] = useState(0.5);
  const [power, setPower] = useState(1.5);
  // const [stateCheck, setStateCheck] = useState(false);
  const [defendGoal, setDefendGoal] = useState(false);
  const [trailStyle, setTrailStyle] = useState({});
  const [trailVisible, setTrailVisible] = useState(false);
  const [targetPositions, setTargetPositions] = useState([]);
  const targetGrid = [
    [10, 10],
    [30, 10],
    [50, 10],
    [10, 30],
    [30, 30],
    [50, 30],
    [10, 50],
    [30, 50],
    [50, 50],
  ];

  const generateRandomTargetPositions = () => {
    const randomPositions = [];
    const totalTargets = 9; // Number of targets to generate

    for (let i = 0; i < totalTargets; i++) {
      const randomIndex = Math.floor(Math.random() * targetGrid.length);
      const position = targetGrid[randomIndex];
      randomPositions.push(position);
    }

    setTargetPositions(randomPositions);
  };

  const [currentTargetIndex, setCurrentTargetIndex] = useState(0);
  const [checkTarget, setCheckTarget] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [ballStyle, setBallStyle] = useState({});
  const [racketStyle, setRacketStyle] = useState({});
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const maxTime = 0.3; // Adjust as needed to control speed of the shot
  const interval = 5; // Milliseconds between each position update
  // Initial position of the ball
  const initialX = screenWidth * 0.52;
  const initialY = screenHeight * 0.735;

  const checkCollision = (ballRect, targetRect) => {
    const ballCenterX = ballRect.left + ballRect.width / 2;
    const ballCenterY = ballRect.top + ballRect.height / 2;
    const targetCenterX = targetRect.left + targetRect.width / 2;
    const targetCenterY = targetRect.top + targetRect.height / 2;

    const distanceX = Math.abs(ballCenterX - targetCenterX);
    const distanceY = Math.abs(ballCenterY - targetCenterY);

    // Calculate the distance between the centers of the ball and the target
    const distanceBetweenCenters = Math.sqrt(distanceX ** 2 + distanceY ** 2);

    // The collision happens when the distance between the centers is less than
    // or equal to the sum of the radii (half of the width for a circle)
    return distanceBetweenCenters <= ballRect.width / 2 + targetRect.width / 2;
  };

  useEffect(() => {
    generateRandomTargetPositions();
    window.addEventListener("resize", generateRandomTargetPositions);

    return () => {
      window.removeEventListener("resize", generateRandomTargetPositions);
    };
  }, []);

  const waitForImagesToLoad = (animation) => {
    return new Promise((resolve) => {
      animation.addEventListener("loaded_images", resolve);
    });
  };

  let lottieAnim;
  let lottieAnim2;
  React.useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation1,
      autoplay: false,
      loop: false,
    });
    const counterAnim = Lottie.loadAnimation({
      container: counterAnimationContainer.current,
      animationData: counteranimation,
      autoplay: true,
      loop: false,
    });
    lottieAnim = anim;
    lottieAnim.stop();
    lottieAnim2 = counterAnim;
    lottieAnim2.stop();

    // Wait for all images to load for both animations
    Promise.all([
      waitForImagesToLoad(lottieAnim),
      waitForImagesToLoad(lottieAnim2),
    ]).then(() => {
      document.getElementById("loader-init").style.display = "none";
      lottieAnim.play();
      lottieAnim2.play();
      setGameStateCheck(true);
      if (currentSession.gameState) {
        setTimer(currentSession.gameState.remainingTime);
        setScore(currentSession.gameState.totalPoints);
      }
      setTimeout(() => {
        setControlState({ ...controlState, isRunning: true });
        refreshScene();
      }, 4000);
      ballRef.current.firstChild.style.top = `${
        animationContainer.current.offsetHeight * 0.71
      }px`;
      ballRef.current.firstChild.style.left = `${
        window.innerWidth / 2 + ballRef.current.firstChild.offsetWidth / 1.5
      }px`;
      // ballRef.current.firstChild.style.transition = 'opacity 0.5s ease-in-out 5.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1)';
      ballRef.current.firstChild.style.transform = "scale(2) rotate(0deg)";
      ballRef.current.firstChild.style.opacity = 1;
    });

    lottieAnim2.addEventListener("complete", () => {
      setAnimationComplete(true); // Set state to true when animation completes
    });

    // Cleanup function
    return () => {
      lottieAnim2.destroy(); // Optional: destroys the animation instance
    };
  }, []);

  React.useEffect(() => {
    isLoading(false);
  });
  const advanceStep = useCallback(() => {
    requestRef.current = requestAnimationFrame(advanceStep);
  }, [controlState.speed]);

  useEffect(() => {
    if (gameStateCheck) {
      setItterations(itterations + 1);
      tenisTimer({
        session: currentSession.id,
        caughtSymbols: rowCellArray,
      })
        .then((res) => {
          if (res.gameState !== null && res.gameState.remainingTime <= 0) {
            setScore(res.gameState.totalPoints);
            setControlState({
              ...controlState,
              isRunning: false,
              sliderSpeed: 3,
            });
            endScreen(res.gameState.totalPoints);
          } else if (res.gameState !== null) {
            setDefendGoal(res.gameState.defend);
            setScore(res.gameState.totalPoints);
            setControlState({
              ...controlState,
              sliderSpeed: res.gameState.speed,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.data == "This session is already finished") {
            setControlState({ ...controlState, isRunning: false });
            handleNextLevel();
          } else if (err.data == "Invalid game state") {
          } else if (
            err.message ==
            "Cannot read properties of undefined (reading 'remainingTime')"
          ) {
            setControlState({ ...controlState, isRunning: false });
          }
        });
      setTimeout(() => {
        setRowCellArray([]);
      }, 100);
      setGameStateCheck(false);
    }
  }, [gameStateCheck, rowCellArray]);

  const decreaseTimer = useCallback(() => {
    setTimer((oldTimer) => {
      setGameStateChecker(true);
      if (oldTimer > 0) {
        if (Math.round(oldTimer % 2) === 0) {
          setGameStateCheck(true);
        }
        const newTimer = oldTimer - 1;
        return newTimer;
      }
      setGameStateCheck(true);
    });
  }, [setTimer]);

  const positionElements = () => {
    const directInd = directionIndicator.current;
    if (directInd) {
      const directionParentWidth = directInd.parentNode.offsetWidth;
      if (parseInt(directInd.style.left) <= (directionParentWidth / 100) * 5) {
        directInd.removeAttribute("style");
        directInd.style.left = `${(directionParentWidth / 100) * 85}px`;
      } else if (
        parseInt(directInd.style.left) >=
        (directionParentWidth / 100) * 75
      ) {
        directInd.removeAttribute("style");
        directInd.style.left = `${directionParentWidth / 100}px`;
      }
      directInd.parentNode.setAttribute(
        "class",
        `slider horizontal anim${controlState.sliderSpeed}`
      );
      directInd.parentNode.style.width = "50%";
      directInd.parentNode.style.height = "8%";
      directInd.style.width = "14%";
      directInd.style.height = "63%";
      directInd.style.marginTop = "1%";
    }
    const powerInd = powerIndicator.current;
    if (powerInd) {
      const powerParentHeight = powerInd.parentNode.offsetHeight;
      if (parseInt(powerInd.style.top) <= (powerParentHeight / 100) * 5) {
        powerInd.removeAttribute("style");
        powerInd.style.top = `${(powerParentHeight / 100) * 85}px`;
      } else if (
        parseInt(powerInd.style.top) >=
        (powerParentHeight / 100) * 75
      ) {
        powerInd.removeAttribute("style");
        powerInd.style.top = `${powerParentHeight / 100}px`;
      }
      powerInd.parentNode.setAttribute(
        "class",
        `slider vertical anim${controlState.sliderSpeed}`
      );
      powerInd.parentNode.style.width = "8%";
      powerInd.parentNode.style.height = "35%";
      powerInd.style.width = "80%";
      powerInd.style.height = "14%";
      powerInd.style.marginLeft = "4%";
    }
  };
  useEffect(() => {
    positionTimerRef.current && clearInterval(positionTimerRef.current);
    if (controlState.sliderSpeed === 1 && controlState.isRunning) {
        positionTimerRef.current = setInterval(positionElements, SPAWN_INTERVAL);
    } else if (controlState.sliderSpeed === 2 && controlState.isRunning) {
        positionTimerRef.current = setInterval(positionElements, 650);
    } else if (controlState.sliderSpeed === 3 && controlState.isRunning) {
        positionTimerRef.current = setInterval(positionElements, 350);
    } else if (controlState.sliderSpeed > 2 && controlState.isRunning) {
        positionTimerRef.current && clearInterval(positionTimerRef.current);
    }
  }, [controlState.isRunning, controlState.sliderSpeed]);

  const refreshScene = () => {
    // Reset the ball position to the center of the screen
    const ballImg = ballRef.current.getElementsByTagName("img");
    if (animationContainer.current) {
      ballImg[0].style.top = `${screenHeight * 0.735}px`;
      ballImg[0].style.left = `${screenWidth * 0.52}px`;
      ballImg[0].style.transform = "scale(2) rotate(0deg)";
      ballImg[0].style.opacity = 1;
    }
    if (powerIndicator.current !== null) {
      const powerParentHeight = powerIndicator.current.parentNode.offsetHeight;
      powerIndicator.current.style.top = `${powerParentHeight / 100}px`;
      powerIndicator.current.parentNode.style.opacity = 0;
    }
    if (directionIndicator.current !== null) {
      const directionParentWidth =
        directionIndicator.current.parentNode.offsetWidth;
      directionIndicator.current.style.left = `${directionParentWidth / 100}px`;
      directionIndicator.current.parentNode.style.opacity = 1;
    }
    // setStateCheck(false);
    setSlotIndex(0);
    let randomIndex = Math.floor(Math.random() * targetGrid.length);
    while (randomIndex === currentTargetIndex) {
      randomIndex = Math.floor(Math.random() * targetGrid.length);
    }
    // console.log(randomIndex);
    setCheckTarget(false);
    setCurrentTargetIndex(randomIndex);
  };

  useEffect(() => {
    // console.log(slotIndex);
    if (slotIndex === 2) {
      let intervalID = null;
      intervalID = shootBall();
      return () => {
        if (intervalID) clearInterval(intervalID);
      };
    } else if (slotIndex === 1) {
      powerIndicator.current.parentNode.style.opacity = 1;
      directionIndicator.current.parentNode.style.transition = "opacity 0s";
      directionIndicator.current.parentNode.style.opacity = 0;
    }
    // console.log('slotIndex only', slotIndex)
  }, [slotIndex]);

  useEffect(() => {
    if (checkTarget) {
      setTimeout(() => {
        // Find the active target based on the class name
        const activeTarget = document.querySelector(".target.active");
        const ballImg = ballRef.current.getElementsByTagName("img");

        if (activeTarget) {
          // Retrieve the position and dimensions of the active target
          const targetRect = activeTarget.getBoundingClientRect();

          // Check if the ball hits the target
          const ballRect = ballImg[0].getBoundingClientRect();
          const doesBallHitTarget = checkCollision(ballRect, targetRect);

          // Update the score if the ball hits the target
          if (doesBallHitTarget) {
            setScore(score + 200);
            setRowCellArray([activeTarget.x, activeTarget.y]);
          }

          // Disable the active target
          activeTarget.classList.remove("active");
        }

        // Increment the current target index
        //   setCurrentTargetIndex(currentTargetIndex + 1);

        // Perform actions after the ball animation
        // setStateCheck(true);
        ballImg[0].src = "images/game/2x/tenis_lopta.png";
        ballImg[0].style.opacity = 0;

        // Reset the ball position and scene
        refreshScene();
      }, 150);
    }
  }, [checkTarget]);

  const shootBall = () => {
    let t = 0;
    const speedFactor = 2; // scale the animations to be 100% faster
    const rotationSpeed = (direction - 0.5) * 1800 * speedFactor;
    const isPortrait = screenHeight > screenWidth;
    const ballSize = ballRef.current.firstChild.width;
    const swingDuration = 88 * speedFactor; // reduced from 300ms to 220ms, then made faster by the speedFactor
  
    const racketSwing =
      direction - 0.5 > 0 ? -45 : direction - 0.5 < 0 ? 45 : 0;
    const directionModifier =
      (isPortrait ? 3.25 : 2.5) * (direction - 0.5) * screenWidth;
    const powerModifier = -(isPortrait ? 1.55 : 2) * (defendGoal ? 2.5 * power : power) * screenHeight;
    // console.log(powerModifier);
  
    const animateRacket = () => {
      setRacketStyle({
        transform: `scale(1.5) rotate(${racketSwing / 2}deg)`,
        transition: `transform ${swingDuration / 2}ms linear`,
      });
  
      let start = null;
      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
  
        if (progress > swingDuration / 2) {
          setRacketStyle({
            transform: `scale(1) rotate(${racketSwing}deg)`,
            transition: `transform ${swingDuration / 2}ms linear`,
          });
        } else {
          requestAnimationFrame(step);
        }
      };
  
      requestAnimationFrame(step);
    };
  
    animateRacket();
    setTrailVisible(true);
  
    let start = null;
    const delayBallAnimation = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
  
      if (progress > swingDuration) {
        animateBall();
      } else {
        requestAnimationFrame(delayBallAnimation);
      }
    };
  
    const animateBall = () => {
      t += (interval / 1000) * speedFactor; // Speed up the passage of time
  
      const scale = 2 - t * 4;
      const x = initialX + directionModifier * t;
      const y = initialY + powerModifier * t;
      const rotate = rotationSpeed * t;
  
      setBallStyle({
        top: `${y}px`,
        left: `${x}px`,
        transform: `scale(${scale}) rotate(${rotate}deg)`,
      });
  
      const angle = (Math.atan2(y - initialY, x - initialX) * 180) / Math.PI;
      const trailWidth = Math.hypot(x - initialX, y - initialY);
      const adjustedInitialX = initialX + (ballSize * scale) / 2;
      const adjustedInitialY = initialY + (ballSize * scale) / 2;
  
      setTrailStyle({
        width: `${trailWidth}px`,
        transform: `translate(${adjustedInitialX}px, ${adjustedInitialY}px) rotate(${angle}deg)`,
      });
  
      if (t <= maxTime) {
        requestAnimationFrame(animateBall);
      } else {
        setCheckTarget(true);
        setTrailStyle({
          width: 0,
        });
        setTrailVisible(false);
      }
    };
  
    requestAnimationFrame(delayBallAnimation);
  }; 
  const stopHorizontalSlider = () => {
    // console.log('Horizontal slide triggered');
    const style = window.getComputedStyle(directionIndicator.current);
    const left = style.getPropertyValue("left");
    let x = parseInt(left.substring(0, 3), 10);
    // console.log('x1:', x);
    x =
      x /
      (directionIndicator.current.parentNode.offsetWidth -
        directionIndicator.current.offsetWidth / 2);
    // if (x > 0.4 && x < 0.6) {
    //     x = 0.5;
    // } else if (x > 0.2 && x < 0.4) {
    //     x = 0.25;
    // } else if (x > 0.6 && x < 0.8) {
    //     x = 0.7;
    // } else if (x > 0 && x < 0.2) {
    //     x = 0.2;
    // } else if (x > 0.8) {
    //     x = 0.85;
    // }
    setDirection(x);
    setSlotIndex(slotIndex + 1);
    // console.log('x2:', x);
  };

  const stopVerticalSlider = () => {
    // console.log('Vertical slide triggered');
    const style = window.getComputedStyle(powerIndicator.current);
    const top = style.getPropertyValue("top");
    let y = parseInt(top.substring(0, 3), 10);
    // console.log('y1:', y);
    y =
      y /
      (powerIndicator.current.parentNode.offsetHeight -
        powerIndicator.current.offsetHeight / 2) /
      1.3;
    // if (y > 0.4 && y < 0.6) {
    //     y = 0.7;
    // } else if (y > 0.2 && y < 0.4) {
    //     y = 0.35;
    // } else if (y > 0.6 && y < 0.8) {
    //     y = 0.75;
    // } else if (y > 0 && y < 0.2) {
    //     y = 0.3;
    // } else if (y > 0.8) {
    //     y = 0.75;
    // }
    // setSlotIndex(slotIndex + 1);
    setPower(1 - y);
    setSlotIndex(slotIndex + 1);
    // console.log('y2:', y);
    powerIndicator.current.parentNode.style.transition = "opacity 0s";
    powerIndicator.current.parentNode.style.opacity = 0;

    // shootTheBall(direction, y);
    // directionIndicator.current.parentNode.style.opacity = 1;
  };

  useEffect(() => {
    const stop = () => {
      timerRef.current && clearInterval(timerRef.current);
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };

    if (controlState.isRunning) {
      setGameStateCheck(true);
      setTimeout(() => {
        timerRef.current = setInterval(decreaseTimer, SPAWN_INTERVAL);
      }, 200);
      requestRef.current = requestAnimationFrame(advanceStep);
    } else {
      stop();
    }
    // return () => stop();
  }, [controlState.isRunning, advanceStep]);

  const clear = useCallback(() => {
    setControlState({ ...controlState, isRunning: false, speed: SPEED_STEP });
    // setScore(0);
    setTimer(0);
  }, [setControlState, setScore, controlState]);
  const rows = [];

  for (let i = 0; i < targetPositions.length; i += 3) {
    const rowElements = targetPositions.slice(i, i + 3);
    rows.push(rowElements);
  }

  return (
    <div className="main" key="game-src-desktop">
      {!animationComplete && (
        <div className="counter-animation" ref={counterAnimationContainer} />
      )}
      <div className="game-scr-animation" ref={animationContainer}>
        <div className="slider horizontal">
          <div ref={directionIndicator} className="small-ball one-end"></div>
        </div>
        <div className="slider vertical">
          <div ref={powerIndicator} className="small-ball one-end"></div>
        </div>
        <div
          className="reket"
          ref={racketRef}
          style={{
            top: `${
              animationContainer.current &&
              animationContainer.current.offsetHeight * 0.65
            }px`,
          }}
        >
          <img
            style={racketStyle}
            src="./images/game/2x/tenis_reket.png"
            width="25%"
          />
        </div>
        <div className="ball" ref={ballRef}>
          <img style={ballStyle} src="images/game/2x/tenis_lopta.png" />
        </div>
        {trailVisible && (
          <div
            className="trail"
            style={{
              zIndex: 12000,
              pointerEvents: "none",
              ...trailStyle,
            }}
          />
        )}
        <div
          className={`hitting-grid container ${
            controlState.isRunning ? "visible" : "hidden"
          }`}
          ref={hitGrid}
        >
          {rows.map((row, rowIndex) => (
            <div className="grid-row row" key={`row-${rowIndex}`}>
              {row.map((_position, cellIndex) => (
                <div
                  key={`cell-${rowIndex}-${cellIndex}`}
                  data-rowcell={`cell-${rowIndex}-${cellIndex}-${currentTargetIndex}`}
                  className="grid-cell"
                >
                  <img
                    src={"images/game/2x/tenis_meta.png"}
                    className={`meta ${
                      currentTargetIndex === rowIndex * 3 + cellIndex
                        ? "active"
                        : ""
                    }`}
                    style={{
                      top: `${(rowIndex * 3 - 2) * 8}px`,
                      width: `${rowIndex * 2 + 27}%`,
                      ...(cellIndex === 0 && {
                        left: `${8 - rowIndex * 6 + 1}%`,
                      }),
                      ...(cellIndex === 2 && { right: `${7 - rowIndex * 6}%` }),
                    }}
                  />
                  <img
                    src={"images/game/circle.png"}
                    className={`target ${
                      currentTargetIndex === rowIndex * 3 + cellIndex
                        ? "active"
                        : ""
                    }`}
                    style={{
                      top: `${
                        (rowIndex === 2
                          ? 9
                          : rowIndex === 1
                          ? 4.5
                          : rowIndex * 3 + 0.35) * 6
                      }px`,
                      width: `${rowIndex * 2 + 20}%`,
                      ...(cellIndex === 0 && {
                        left: `${8 - rowIndex * 6 + 5}%`,
                      }),
                      ...(cellIndex === 2 && {
                        right: `${7 - rowIndex * 6 + 4}%`,
                      }),
                      opacity: 0,
                    }}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          className="mreza visible"
          style={{
            top: `${
              animationContainer.current &&
              animationContainer.current.offsetHeight * 0.53
            }px`,
          }}
        >
          <img src="./images/game/mreza.png" width="100%" />
        </div>
        <div
          className={`panel ${controlState.isRunning ? "visible" : "hidden"}`}
        >
          <Control onClear={clear} />
          <Score />
          <Timer />
        </div>
      </div>
      {slotIndex === 0 && (
        <div
          className="game_btn horizontal"
          onMouseDown={stopHorizontalSlider}
          style={{
            top: `${
              animationContainer.current &&
              animationContainer.current.offsetHeight * 0.85
            }px`,
          }}
        >
          <img
            src="./images/game/pogodi_pravac_button.svg"
            alt="Podesi horizontalno"
            width="50%"
          />
        </div>
      )}
      {slotIndex === 1 && (
        <div
          className="game_btn vertical"
          onMouseDown={stopVerticalSlider}
          style={{
            top: `${
              animationContainer.current &&
              animationContainer.current.offsetHeight * 0.85
            }px`,
          }}
        >
          <img
            src="./images/game/pogodi_jacinu_button.svg"
            alt="Podesi vertikalno"
            width="50%"
          />
        </div>
      )}
    </div>
  );
};
